import { __rest } from "tslib";
import React, { forwardRef } from 'react';
import { styled } from '@glitz/react';
import { ArrowDown } from '../Icon';
import { createGeneralDecorator, createTransitionDecorator, general, defaultLineHeight, grey60, beta, omega, lightBlue, thin, stateRed, outlineGrey, tiny, placeholderGrey, createTruncateDecorator, white, large, radius, black05, } from '../Style';
import { createTabbingFocusWithinElement } from 'Shared/a11y/TabFocus';
import { fieldDecorator } from './Text';
const DropDownInternal = forwardRef((props, ref) => {
    const { children, options, onChangeOption, onChange, isMobileFilter, disabled, placeholder, externalLabel, className, required } = props, restProps = __rest(props, ["children", "options", "onChangeOption", "onChange", "isMobileFilter", "disabled", "placeholder", "externalLabel", "className", "required"]);
    function customOnChange(e) {
        if (onChange) {
            onChange(e);
        }
        if (onChangeOption) {
            onChangeOption(options[e.currentTarget.selectedIndex].value);
        }
    }
    const styledSelect = Object.assign(Object.assign(Object.assign({}, (props.error && { border: { xy: { color: stateRed } } })), (props.height && { height: props.height })), (props.width && { width: props.width }));
    return isMobileFilter ? (React.createElement(styled.Div, null,
        placeholder && !restProps.value && React.createElement(Placeholder, null, placeholder),
        React.createElement(MobileSelect, Object.assign({ ref: ref, onChange: customOnChange }, restProps, { css: styledSelect, disabled: disabled }), options.map(option => (React.createElement("option", { key: option.value, value: option.value }, option.text)))))) : (React.createElement(styled.Div, null,
        externalLabel && React.createElement(ExternalLabel, null, externalLabel),
        placeholder && !restProps.value && React.createElement(Placeholder, null, placeholder),
        React.createElement(Select, Object.assign({ required: required, ref: ref, onChange: customOnChange }, restProps, { css: styledSelect, disabled: disabled, className: className }), options.map(option => (React.createElement("option", { key: option.value, value: option.value }, option.text)))),
        React.createElement(Toggle, { color: grey60 })));
});
const DropDown = createTabbingFocusWithinElement(styled(DropDownInternal, {
    position: 'relative',
    display: 'inline-flex',
    alignItems: 'center',
}));
export default DropDown;
export const DropDownFieldSet = styled(DropDown, fieldDecorator);
export const resetSelectDecorator = styled({
    appearance: 'none',
    padding: { xy: 0 },
    font: {
        family: 'inherit',
        size: 'inherit',
        style: 'inherit',
        variant: 'inherit',
        weight: 'inherit',
    },
    color: 'inherit',
    border: { xy: { width: 0 } },
    backgroundColor: ['transparent', 'initial'],
    ':focus': {
        outlineWidth: 0,
        outlineStyle: 'none',
    },
});
const Select = styled(styled.Select, resetSelectDecorator, createGeneralDecorator(), {
    width: '100%',
    paddingRight: general().padding.x * 2 + defaultLineHeight,
    border: { xy: { color: outlineGrey, style: 'solid', width: thin } },
    borderRadius: 2,
    height: omega,
    ':focus-within': {
        borderRadius: 2,
        width: '100%',
        border: {
            xy: {
                color: lightBlue,
                style: 'solid',
                width: thin,
            },
        },
    },
    ':disabled': {
        backgroundColor: black05,
    },
});
const MobileSelect = styled(styled.Select, resetSelectDecorator, createGeneralDecorator(), {
    minWidth: '100%',
});
const Toggle = styled(ArrowDown, Object.assign({ position: 'absolute', right: beta, pointerEvents: 'none' }, createTransitionDecorator({ property: 'transform' })));
const Placeholder = styled.span(Object.assign({ display: 'flex', alignItems: 'center', position: 'absolute', top: 0, bottom: 0, width: '100%', paddingLeft: large, overflow: 'hidden', color: placeholderGrey, backgroundColor: white, border: { xy: { color: outlineGrey, style: 'solid', width: thin }, radius: radius }, pointerEvents: 'none' }, createTruncateDecorator()));
const ExternalLabel = styled.span({
    position: 'absolute',
    top: -tiny,
    left: 0,
    fontSize: 15,
});
