let siteSettings = null;
export function setKnownUrls(settings) {
    siteSettings = settings;
}
export function searchPageUrl() {
    return siteSettings.searchPage.url;
}
export function checkoutPageUrl() {
    return siteSettings.checkoutPage.url;
}
export function myDetailsPageUrl() {
    return siteSettings.myDetailsPage.url;
}
export function storeListPageUrl() {
    return siteSettings.storeListPage;
}
export function loginPageUrl() {
    return siteSettings.loginPage;
}
export function contactUsPageUrl() {
    return siteSettings.contactUsPage;
}
export function dealerPortalPageUrl() {
    return siteSettings.dealerPortalUrl;
}
export function applicationFormB2BPageUrl() {
    return siteSettings.applicationFormB2BPage;
}
export function myAddressesPage() {
    return siteSettings.myAddressesPage;
}
export function mtmHelpPageUrl() {
    var _a;
    return (_a = siteSettings.madeToMeasureHelpPage) === null || _a === void 0 ? void 0 : _a.url;
}
export function findRetailerUrl() {
    return siteSettings.findRetailerPage;
}
export function deleteAccountConfirmationPage() {
    var _a;
    return (_a = siteSettings.deleteAccountConfirmationPage) === null || _a === void 0 ? void 0 : _a.url;
}
